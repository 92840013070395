import React, { useState } from "react";

import { Grid, TextField } from "@mui/material";
import { useContext } from "react";
import invoicesContext from "./../../../context/Invoices/InvoicesContext";
import { useEffect } from "react";
import TableSalesForInvoice from "./TableSalesForInvoice";
import { useDebounce } from "use-debounce";
import NoDataComponents from "../../../components/loading/NoDataComponents";
import SearchingComponent from "../../../components/loading/SearchingComponent";
import NoResultsComponent from "../../../components/loading/NoResultsComponent";
import { Paginate } from "../../../components/Pagination/Paginate";
const SalesForInvoice = () => {
  const {
    GetSalesForInvoice,
    ordersForInvoice,
    totalPages,
    ordersForInvoiceNp,
    GetSalesForInvoiceNoPaginate,
  } = useContext(invoicesContext);
  var [pages, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const limite = rowsPerPage;
  const params = { limite, pages };
  useEffect(() => {
    GetSalesForInvoice(params);
  }, [rowsPerPage, pages]);
  /**Buscador */
  const [magicWord, setMagicWord] = useState("");
  const [debounceWord] = useDebounce(magicWord, 1500);
  const [search, saveSearch] = useState([]);

  const handleSearchSale = () => {
    try {
      // if (debounceWord !== "") {
      if (ordersForInvoiceNp !== "") {
        let searchResult = ordersForInvoiceNp.filter(
          (sales) =>
            sales.folio.toString().toLowerCase() === magicWord.toLowerCase() ||
            sales.client_id.fullname
              .toString()
              .toLowerCase()
              .includes(debounceWord.toLowerCase())
        );
        saveSearch(searchResult);
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetSalesForInvoiceNoPaginate();
  }, []);
  useEffect(() => {
    saveSearch(ordersForInvoiceNp);
  }, []);

  useEffect(() => {
    handleSearchSale();
  }, [debounceWord]);
  /**Termina Buscador */
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{ marginBottom: 2 }}
      >
        {ordersForInvoice.length && (
          <TextField
            placeholder='Buscar por folio o nombre de cliente'
            type='text'
            fullWidth
            onChange={(e) => {
              setMagicWord(e.target.value);
              saveSearch(null);
            }}
          />
        )}
      </Grid>
      {!magicWord &&
        (!ordersForInvoice.length ? (
          <NoDataComponents />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableSalesForInvoice orders={ordersForInvoice} />
          </Grid>
        ))}
      {magicWord &&
        (!search ? (
          <SearchingComponent />
        ) : search.length === 0 ? (
          <NoResultsComponent />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TableSalesForInvoice orders={search} />
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {magicWord ? (
          <></>
        ) : (
          ordersForInvoice.length > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12} display='flex' justifyContent='center'>
                <Paginate
                  pages={pages}
                  totalPages={totalPages}
                  handleChangePage={handleChangePage}
                />
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default SalesForInvoice;
