import { Card, Icon, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ModulesContext from "../../context/Permissions/PermissionsContext";
import AuthContext from "../../context/auth/authContext";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  icon: {
    // objectFit: "cover",
    fontSize: 90,
    color: "black",
    //marginRight: 5,
  },
  div_ganancias: {
    marginRight: "5%",
    marginLeft: "10%",
    textAlign: "center",
    // color: "white",
    //fontWeight: "bold",
  },
}));
const CardMenu = ({ data }) => {
  const { usuario } = useContext(AuthContext);
  const { module_id } = data;
  const { routes, icon, backgroundColor, name, color } = module_id;
  const [opendropdown, setDropdown] = useState(false);
  const [module, setModule] = useState("");
  const { permissionsPerUser, GetModulesPerUser, success } =
    useContext(ModulesContext);
  const handleClickModule = (e) => {
    GetModulesPerUser(e);
  };
  const classes = useStyles();
  return (
    <div>
      {routes.map((j, index) => {
        return (
          <Link
            to={j.path}
            onClick={() => handleClickModule(module_id._id)}
            key={index}
          >
            <Card
              style={{ backgroundColor: backgroundColor }}
              sx={{
                boxShadow: 10,
                fontSize: 60,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div className={classes.div_ganancias}>
                {/* <Icon className={classes.icon}> */}
                <Typography fontSize='65px'>
                  <i className={icon} />
                </Typography>
                {/* </Icon> */}
              </div>
              <br />
              <div className={classes.div_ganancias}>
                <Typography
                  fontWeight='bold'
                  fontFamily='monospace'
                  component='div'
                  variant='h5'
                >
                  {name}
                </Typography>
              </div>
            </Card>
          </Link>
        );
      })}
    </div>
  );
};

export default CardMenu;
