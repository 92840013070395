import React, { useReducer } from "react";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../config/Service";
import {
  CANCEL_INVOICE,
  CREATE_GLOBAL_INVOICE,
  GET_CONCILIATIONS,
  GET_INVOICES_CANCELED,
  GET_REQUEST_FOR_CANCEL_INVOICE,
  GET_SALES_FOR_INVOICE_GLOBAL,
  GET_SALES_FOR_INVOICE_NO_PAGINATE,
  GET_SALES_INVOICED,
  GET_SALES_INVOICED_NO_PAGINATE,
  INVOICE_ORDER,
  REGISTER_SAT_DIRECTION,
  SHOW_ERRORS_API,
} from "../../types";
import InvoicesContext from "./InvoicesContext";
import InvoicesReducer from "./InvoicesReducer";
import fileDownload from "js-file-download";
import { GET_SALES_FOR_INVOICE } from "./../../types/index";

const InvoicesState = ({ children }) => {
  const initialState = {
    orders: [],
    globalInvoice: [],
    conciliations: [],
    saleInvoiced: null,
    ErrorsApi: [],
    requestCancel: [],
    invoicesCanceled: [],
    ordersForInvoice: [],
    ordersInvoicedNp: [],
    ordersForInvoiceNp: [],
    success: false,
  };

  const [state, dispatch] = useReducer(InvoicesReducer, initialState);

  const GetSalesInvoiced = (params) => {
    sessionStorage.removeItem("saleInvoiced");
    // params.pages += 1;
    let url = `/orders/invoice/all?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_SALES_INVOICED,
          payload: {
            orders: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  const GetSalesInvoicedNP = () => {
    let url = "/orders/no-pagination-invoice/all";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_SALES_INVOICED_NO_PAGINATE,
          payload: res.data.orders,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetSalesForInvoice = (params) => {
    // sessionStorage.removeItem("salePendingForInvoiced");
    //params.pages += 1;
    let url = `/orders/invoice/pending?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_SALES_FOR_INVOICE,
          payload: {
            ordersForInvoice: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.error.message,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  const GetSalesForInvoiceNoPaginate = () => {
    let url = "/orders/no-pagination-invoice/pending";
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_SALES_FOR_INVOICE_NO_PAGINATE,
          payload: res.data.orders,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const GetSalesForGlobalInvoice = (data) => {
    let url = `/orders/invoice/orders`;
    MethodPost(url, data)
      .then((res) => {
        //console.log(res.data.orders);
        dispatch({
          type: GET_SALES_FOR_INVOICE_GLOBAL,
          payload: res.data.orders,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.message,
        });
      });
  };
  const GetConciliations = () => {
    let url = `/orders/conciliations/all`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_CONCILIATIONS,
          payload: res.data.conciliations,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const CreateGlobalInvocie = (data) => {
    let url = "/orders/invoice-global";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_GLOBAL_INVOICE,
          payload: res.data.conciliations,
        });
        Swal.fire({
          title: "Facturadas",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const OrderInvoice = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "La venta seleccionada se facturará",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, Facturar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `/orders/${id}`;
        MethodGet(url)
          .then((res) => {
            Swal.fire({
              title: "Información de la Venta",
              html: `
              <p><strong>Venta:</strong> ${res.data.order.folio}</p>
              <p><strong>Cliente:</strong> ${res.data.order.client_id.fullname}</p>
              <p><strong>Total:</strong> $${res.data.order.total}</p>
              <p>Seleccione el método de pago:</p>
              <select id="paymentMethod" class="swal2-input">
                <option value="04">Tarjeta de crédito</option>
                <option value="28">Tarjeta de debito</option>
                <option value="03">Transferencia bancaria</option>
              </select>
            `,
              icon: "info",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Facturar",
              cancelButtonText: "Cancelar",
              preConfirm: () => {
                const paymentMethod =
                  document.getElementById("paymentMethod").value;
                if (!paymentMethod) {
                  Swal.showValidationMessage(
                    "Por favor, selecciona un método de pago"
                  );
                }
                return paymentMethod;
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const paymentMethod = result.value;
                let url = `/orders/invoice-admin/${id}`;
                MethodPost(url, { payment_method: paymentMethod })
                  .then(() => {
                    Swal.fire({
                      title: "¡Facturado!",
                      text: "La venta se ha facturado correctamente.",
                      icon: "success",
                    });
                    dispatch({
                      type: INVOICE_ORDER,
                      payload: id,
                    });
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "Error",
                      text:
                        error.response?.data?.message ||
                        "Ha ocurrido un error.",
                      icon: "error",
                    });
                    dispatch({
                      type: SHOW_ERRORS_API,
                    });
                  });
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No se pudo obtener la información de la venta.",
              icon: "error",
            });
            console.log(error);
          });
      }
    });
  };

  const GetPdfInvoice = (id) => {
    Swal.fire({
      title: "Descargar",
      text: "Se descargara la factura",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/invoice/pdf/${id}`;
        MethodGet(url, { responseType: "blob" })
          .then((res) => {
            fileDownload(res.data, "Factura.pdf");
            Swal.fire({
              title: "Descargado",
              text: "La factura se ha descargado, correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Se ha producido un error al descargar el PDF",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const GetZipInvoice = (id) => {
    Swal.fire({
      title: "Descargar",
      text: "Se descargara el zip con la factura y el xml",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, descargar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/invoice/zip/${id}`;
        MethodGet(url, { responseType: "arraybuffer" })
          .then((res) => {
            const url = window.URL.createObjectURL(
              new Blob([res.data], { type: "application/zip" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "file.zip");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            //fileDownload(res.data, 'Factura.zip');
            Swal.fire({
              title: "Descargado",
              text: "La factura se ha descargado, correctamente",
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "Se ha producido un error al descargar el PDF",
              icon: "error",
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const CancelInvoice = (id) => {
    Swal.fire({
      title: "Cancelar",
      text: "Se cancelara la factura de la venta seleccionada",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar Factura",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/orders/invoice/cancel/${id}`;
        MethodPost(url)
          .then((res) => {
            Swal.fire({
              title: "Cancelada",
              text: "La factura se ha cancelado correctamente",
              icon: "success",
              //timer: 1500,
              showConfirmButton: true,
            });
            dispatch({
              type: CANCEL_INVOICE,
              payload: id,
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              icon: "error",
              text: error.response.data.message,
            });
            dispatch({
              type: SHOW_ERRORS_API,
            });
          });
      }
    });
  };

  const GetRequestForCancelInvoice = (params) => {
    let url = `/orders/invoice-canceled/all?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: GET_REQUEST_FOR_CANCEL_INVOICE,
          payload: {
            requestCancel: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
            totalPages: res.data.totalPages,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetInvoicesCanceled = (params) => {
    let url = `/orders/invoice/canceled?limit=${params.limite}&page=${params.pages}`;
    MethodGet(url)
      .then((res) => {
        dispatch({
          type: GET_INVOICES_CANCELED,
          payload: {
            invoicesCanceled: res.data.orders,
            totalDocs: res.data.totalDocs,
            limit: res.data.limit,
            page: res.data.page,
            paginCounter: res.data.paginCounter,
            hasPrevPage: res.data.hasPrevPage,
            hasNextPage: res.data.hasNextPage,
            prevPage: res.data.prevPage,
            nextPage: res.data.nextPage,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RegisterSatDirection = (data) => {
    let url = `/clients/save-sat-direction/${data.idClient}`;
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: REGISTER_SAT_DIRECTION,
          payload: res.data.client,
        });
        Swal.fire({
          title: "Regitrada",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `Mensaje: ${error.response.data.message} , error: ${error.response.data.error}`,
          icon: "error",
        });
      });
  };

  return (
    <InvoicesContext.Provider
      value={{
        orders: state.orders,
        globalInvoice: state.globalInvoice,
        ErrorsApi: state.ErrorsApi,
        success: state.success,
        totalDocs: state.totalDocs,
        limit: state.limit,
        page: state.page,
        paginCounter: state.paginCounter,
        hasPrevPage: state.hasPrevPage,
        prevPage: state.prevPage,
        hasNextPage: state.hasNextPage,
        nextPage: state.nextPage,
        totalPages: state.totalPages,
        conciliations: state.conciliations,
        requestCancel: state.requestCancel,
        invoicesCanceled: state.invoicesCanceled,
        ordersForInvoice: state.ordersForInvoice,
        ordersInvoicedNp: state.ordersInvoicedNp,
        ordersForInvoiceNp: state.ordersForInvoiceNp,
        CreateGlobalInvocie,
        GetSalesInvoiced,
        GetSalesForGlobalInvoice,
        GetSalesForInvoice,
        OrderInvoice,
        GetPdfInvoice,
        GetZipInvoice,
        CancelInvoice,
        GetConciliations,
        GetRequestForCancelInvoice,
        GetInvoicesCanceled,
        GetSalesInvoicedNP,
        GetSalesForInvoiceNoPaginate,
        RegisterSatDirection,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export default InvoicesState;
